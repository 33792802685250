const convertDate = (strDt) => {
  if (strDt === undefined || strDt === null || strDt === '') {
    return ''
  }
  const month = {
    K01: 'JAN',
    K02: 'FEB',
    K03: 'MAR',
    K04: 'APR',
    K05: 'MAY',
    K06: 'JUN',
    K07: 'JUL',
    K08: 'AUG',
    K09: 'SEP',
    K10: 'OCT',
    K11: 'NOV',
    K12: 'DEC'
  }
  const key = 'K' + strDt.substring(4, 6)
  const m = month[key] || ''
  const d = strDt.substring(6, 8)
  const t = strDt.substring(8, 10)
  return `${m} ${d} ${t}H`
}

// 컨테이너 정보 영역
const rendererCntrQty = (grid, cell) => {
  const idx = cell.index.dataRow
  const cntrQty = vmApp.gridList[idx].cntrQty
  const dgCertiCnt = vmApp.gridList[idx].dgCertiCnt
  const hzYn = vmApp.gridList[idx].hzYn
  // const cntrQty = grid.getValue(idx, 'cntrQty')
  // const dgCertiCnt = grid.getValue(idx, 'dgCertiCnt')
  // const hzYn = grid.getValue(idx, 'hzYn')
  if (dgCertiCnt > -1) {
    return `${cntrQty.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">DG Certi. (${dgCertiCnt})</span>`
  } else if (hzYn === 'Y') {
    return `${cntrQty.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">HZ</span>`
  } else {
    return cntrQty.split(',').join('<br/>')
  }
}
// 경과일 (BL 단위)
const rendererBlPolEtd = (grid, cell) => {
  const idx = cell.index.dataRow
  const blPolEtd = vmApp.gridList[idx].blPolEtd
  // const blPolEtd = grid.getValue(idx, 'blPolEtd')
  return app.$ekmtcCommon.getDateDiffWithToday(blPolEtd)
}

const rendererCntrNo = (grid, cell, w, h) => {
  const idx = cell.index.dataRow
  const cntrList = vmApp.gridList[idx].cntrList || []
  // const cntrList = grid.getValue(idx, 'cntrList') || []
  if (cntrList.length === 0) {
    return ''
  } else {
    if (cntrList.length > 3) {
      const rowH = 60 + ((cntrList.length - 3) * 18) - 6
      setTimeout(function () {
        grid.setRowHeight(idx, rowH, true)
      }, 500)
    }
    return cntrList.map(vo => '<p>' + vo.cntrNo + '</p>').join('')
  }
}

const rendererCntrDet = (grid, cell, demDetCd) => {
  const idx = cell.index.dataRow
  const cntrList = vmApp.gridList[idx].cntrList || []
  const bkgNo = vmApp.gridList[idx].bkgNo
  const blNo = vmApp.gridList[idx].blNo
  if (cntrList.length === 0) {
    return ''
  } else {
    return '<button typwe="button" class="button sm request" onclick="vmApp.$refs.commonUnsolved.fnDemInvoiceMultiPop(event, \'' + bkgNo + '\', \'' + blNo + '\', \'O\', \'' + demDetCd + '\')">' + app.$t('msg.ONEX010T010.143') + '</button>' // 금액확인
  }
}

const rendererCntrDt = (grid, cell, calDt) => {
  const idx = cell.index.dataRow
  const cntrList = vmApp.gridList[idx].cntrList || []
  // const cntrList = grid.getValue(idx, 'cntrList') || []
  if (cntrList.length === 0) {
    return ''
  } else {
    if (calDt) {
      return '<p>' + vmApp.$ekmtcCommon.getDateDiffWithToday(calDt) + '</p>'
    } else {
      return cntrList.map(vo => '<p>' + vmApp.$ekmtcCommon.getDateDiffWithToday(vo.strDt) + '</p>').join('')
    }
  }
}

const fields = [
  { fieldName: 'bkgNo', dataType: 'text' },
  { fieldName: 'bkgStsCd', dataType: 'text' },
  { fieldName: 'srRno', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'blStsCd', dataType: 'text' },
  { fieldName: 'caStsCd', dataType: 'text' },
  { fieldName: 'shprRspsYn', dataType: 'text' },
  { fieldName: 'shprRspsBtnYn', dataType: 'text' },
  { fieldName: 'mtyPkupUsagYn', dataType: 'text' },
  { fieldName: 'actShprCstEnm', dataType: 'text' },
  { fieldName: 'cgoModeCd', dataType: 'text' },
  { fieldName: 'por', dataType: 'text' },
  { fieldName: 'dly', dataType: 'text' },
  { fieldName: 'etd', dataType: 'text' },
  { fieldName: 'eta', dataType: 'text' },
  { fieldName: 'statusCd', dataType: 'text' },
  { fieldName: 'polCtrCd', dataType: 'text' },
  { fieldName: 'polPortCd', dataType: 'text' },
  { fieldName: 'polPortNm', dataType: 'text' },
  { fieldName: 'podCtrCd', dataType: 'text' },
  { fieldName: 'podPortCd', dataType: 'text' },
  { fieldName: 'podPortNm', dataType: 'text' },
  { fieldName: 'vslCd', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'cntrQty', dataType: 'text' },
  { fieldName: 'dgCertiCnt', dataType: 'Number' },
  { fieldName: 'srYn', dataType: 'text' },
  { fieldName: 'docDtm', dataType: 'text' },
  { fieldName: 'cntrCntChk', dataType: 'text' },
  { fieldName: 'vgmStatus', dataType: 'text' },
  { fieldName: 'blCntrQty', dataType: 'Number' },
  { fieldName: 'shprCntrQty', dataType: 'Number' },
  { fieldName: 'bkgAcpnMethCd', dataType: 'text' },
  { fieldName: 'issueReason', dataType: 'text' },
  { fieldName: 'issueKind', dataType: 'text' },
  { fieldName: 'issueDate', dataType: 'text' },
  { fieldName: 'elYn', dataType: 'text' },
  { fieldName: 'frtYn', dataType: 'text' },
  { fieldName: 'cgoTypCd', dataType: 'text' },
  { fieldName: 'hzYn', dataType: 'text' },
  { fieldName: 'rvsdEndpDtm', dataType: 'text' },
  { fieldName: 'rvsdEndpYn', dataType: 'text' },
  { fieldName: 'msnNo', dataType: 'text' },
  { fieldName: 'cnclRsnRmk', dataType: 'text' },
  { fieldName: 'cnclRsnCd', dataType: 'text' },
  { fieldName: 'cnclRsnDsc', dataType: 'text' },
  { fieldName: 'anChk', dataType: 'text' },
  { fieldName: 'anEmail', dataType: 'text' },
  { fieldName: 'doChk', dataType: 'text' },
  { fieldName: 'docs01', dataType: 'text' },
  { fieldName: 'docs02', dataType: 'text' },
  { fieldName: 'docs03', dataType: 'text' },
  { fieldName: 'docs04', dataType: 'text' },
  { fieldName: 'docs05', dataType: 'text' },
  { fieldName: 'cntrPickUp', dataType: 'text' },
  { fieldName: 'cntrGti', dataType: 'text' },
  { fieldName: 'cntrLdg', dataType: 'text' },
  { fieldName: 'cntrDis', dataType: 'text' },
  { fieldName: 'cntrGto', dataType: 'text' },
  { fieldName: 'cntrRtn', dataType: 'text' },
  { fieldName: 'tel', dataType: 'text' },
  { fieldName: 'reqDtm', dataType: 'text' },
  { fieldName: 'tempData', dataType: 'text' },
  { fieldName: 'amtUsd', dataType: 'Number' },
  { fieldName: 'amtLocal', dataType: 'Number' },
  { fieldName: 'diffDayCnt', dataType: 'Number' },
  { fieldName: 'blPolEtd', dataType: 'text' },
  { fieldName: 'blTypCd', dataType: 'text' },
  { fieldName: 'cgoClt', dataType: 'text' },
  { fieldName: 'cntrList', dataType: 'Array' },
  { fieldName: 'fstEntDtm', dataType: 'text' },
  { fieldName: 'fwdrCstNo', dataType: 'text' },
  { fieldName: 'isFwdrYn', dataType: 'text' }
]

// 공통
const defCol = [
  {
    name: 'bkgNo',
    fieldName: 'bkgNo',
    header: { text: app.$t('msg.ONEX010T010.045') }, // Booking No
    width: '80',
    editable: false
  },
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: app.$t('msg.ONEX010T010.046') }, // B/L No
    width: '85',
    editable: false
  },
  {
    name: 'actShprCstEnm',
    fieldName: 'actShprCstEnm',
    header: { text: app.$t('msg.ONEX010T010.048') },
    width: '100',
    editable: false,
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'por',
    fieldName: 'etd',
    type: 'text',
    header: { text: app.$t('msg.ONEX010T010.049') }, // 출발지 ETD
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const por = vmApp.gridList[idx].por || ''
        const etd = vmApp.gridList[idx].etd || ''
        // const por = grid.getValue(idx, 'por')
        // const etd = grid.getValue(idx, 'etd')
        const etdText = convertDate(etd)
        return `${por} <br><span class="font_11">${etdText}</span>`
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'dly',
    fieldName: 'eta',
    type: 'text',
    header: { text: app.$t('msg.ONEX010T010.050') }, // 도착지 ETA
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const dly = vmApp.gridList[idx].dly || ''
        const eta = vmApp.gridList[idx].eta || ''
        // const dly = grid.getValue(idx, 'dly')
        // const eta = grid.getValue(idx, 'eta')
        const etaText = convertDate(eta)
        return `${dly} <br><span class="font_11">${etaText}</span>`
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    header: { text: app.$t('msg.ONEX010T010.051') },
    width: '100',
    editable: false,
    // sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const vslNm = vmApp.gridList[idx].vslNm
        const voyNo = vmApp.gridList[idx].voyNo
        return `${vslNm}<br/>${voyNo}`
      }
    },
    exportStyleName: 'rg-export-excel-cell'
}
]

const columnTyp01 = [
  {
    name: 'cntrQty',
    fieldName: 'cntrQty',
    header: { text: app.$t('msg.ONEX010T010.052') }, // Container
    width: '80',
    editable: false,
    // sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrQty(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'amtUsd',
    fieldName: 'amtUsd',
    header: { text: app.$t('msg.ONGO080G010.008') }, // 상세
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blNo = vmApp.gridList[idx].blNo
        const amtUsd = vmApp.gridList[idx].amtUsd
        const amtLocal = vmApp.gridList[idx].amtLocal

        const localCd = vmApp.memberDetail.userCtrCd
        const localCurCd = vmApp.gridList[idx].locCurCd
        // const blNo = grid.getValue(idx, 'blNo')
        // const amtUsd = grid.getValue(idx, 'amtUsd')
        // const amtLocal = grid.getValue(idx, 'amtLocal')

        const txtAmtUsd = vmApp.$ekmtcCommon.changeNumberFormat('' + amtUsd, { isComma: true })
        const txtAmtLocal = vmApp.$ekmtcCommon.changeNumberFormat('' + amtLocal, { isComma: true })

        if (amtUsd === 0 && amtLocal === 0) {
          return `<a class="link" style="white-space: pre-line;" onclick="vmApp.fnFrtRequest(event, '${blNo}');">${app.$t('msg.ONGO080G010.026')}</a>`
        } else {
          return `USD ${txtAmtUsd}<br/> ${localCurCd + ' ' + txtAmtLocal}`
        }
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'blPolEtd',
    fieldName: 'diffDayCnt',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false
  }
]

const columnTyp02 = [
  {
    name: 'cntrQty',
    fieldName: 'cntrQty',
    header: { text: app.$t('msg.ONEX010T010.052') }, // Container
    width: '80',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrQty(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'blTypCd',
    fieldName: 'blTypCd',
    header: { text: app.$t('msg.ONGO080G010.010') }, // 예정 Type
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blTypCd = vmApp.gridList[idx].blTypCd || ''
        // const blTypCd = grid.getValue(idx, 'blTypCd') || ''
        let blTypNm = ''
        if (blTypCd === '06') {
          blTypNm = 'O.B/L'
        } else if (blTypCd === '02') {
          blTypNm = 'SUR'
        } else if (blTypCd === '03') {
          blTypNm = 'SWB'
        }
        return blTypNm
      }
    }
  },
  {
    name: 'unissueType',
    fieldName: 'blNo',
    header: { text: app.$t('msg.ONGO080G010.011') }, // 구분
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const bkgNo = vmApp.gridList[idx].bkgNo || ''
        const blNo = vmApp.gridList[idx].blNo || ''
        // const bkgNo = grid.getValue(idx, 'bkgNo') || ''
        // const blNo = grid.getValue(idx, 'blNo') || ''
        return `<a href="#" onclick="vmApp.fnIssue(event, '${bkgNo}', '${blNo}')">${app.$t('msg.ONGO080G010.002')}</a>` // 미발행
      }
    }
  },
  {
    name: 'blPolEtd',
    fieldName: 'diffDayCnt',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false
  }
]

const columnTyp03 = [
  {
    name: 'cgoClt',
    fieldName: 'cgoClt',
    header: { text: app.$t('msg.ONGO080G010.012') }, // CGO CLT
    width: '80',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const cgoClt = vmApp.gridList[idx].cgoClt
        // const cgoClt = grid.getValue(idx, 'cgoClt')
        return convertDate(cgoClt)
      }
    }
  },
  {
    name: 'cntrList01',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.013') }, // 컨테이너
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrNo(grid, cell, w, h)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'cntrList02',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.014') }, // DET 상세
    width: 100,
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDet(grid, cell, '02')
      }
    }
  },
  {
    name: 'cntrList03',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDt(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  }
]

const columnTyp04 = [
  {
    name: 'cntrList01',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.013') }, // 컨테이너
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrNo(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'cntrList02',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.032') }, // DEM 상세
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDet(grid, cell, '01')
      }
    }
  },
  {
    name: 'cntrList03',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDt(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  }
]

const columnTyp05 = [
  {
    name: 'cntrList01',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.013') }, // 컨테이너
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrNo(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'cntrList02',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.032') }, // DEM 상세
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDet(grid, cell, '01')
      }
    }
  },
  {
    name: 'cntrList03',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const eta = vmApp.gridList[idx].eta || ''
        return rendererCntrDt(grid, cell, eta)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  }
]

const columns1 = [
  ...defCol,
  ...columnTyp01
]

const columns2 = [
  ...defCol,
  ...columnTyp02
]

const columns3 = [
  ...defCol,
  ...columnTyp03
]

const columns4 = [
  ...defCol,
  ...columnTyp04
]

const columns5 = [
  ...defCol,
  ...columnTyp05
]

const defLayer = [
  'bkgNo',
  'blNo',
  'actShprCstEnm',
  'por',
  'dly',
  'vslNm'
]

const layerTyp01 = [
  'cntrQty',
  {
    name: 'typ01',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.015') }, // '미정산/미청구 현황'
    items: [
      'amtUsd',
      'blPolEtd'
    ],
    exportStyleName: 'rg-export-excel-cell'
  }
]

const layoutTyp01 = [
  ...defLayer,
  ...layerTyp01
]

const layerTyp02 = [
  'cntrQty',
  {
    name: 'typ02',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.016') }, // 미발행 현황
    items: [
      // 'blTypCd',
      // 'unissueType',
      'blPolEtd'
    ],
    exportStyleName: 'rg-export-excel-cell'
  }
]

const layoutTyp02 = [
  ...defLayer,
  ...layerTyp02
]

const layerTyp03 = [
  'cgoClt',
  {
    name: 'typ03',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.017') }, // 미반입 현황
    items: [
      'cntrList01',
      // 'cntrList02',
      'cntrList03'
    ]
  }
]

const layoutTyp03 = [
  ...defLayer,
  ...layerTyp03
]

const layerTyp04 = [
  {
    name: 'typ04',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.018') }, // 미선적 현황
    items: [
      'cntrList01',
      // 'cntrList02',
      'cntrList03'
    ]
  }
]

const layoutTyp04 = [
  ...defLayer,
  ...layerTyp04
]

const layerTyp05 = [
  {
    name: 'typ05',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.033') }, // 미반출 현황
    items: [
      'cntrList01',
      'cntrList02',
      'cntrList03'
    ]
  }
]

const layoutTyp05 = [
  ...defLayer,
  ...layerTyp05
]

export default {
  convertDate,
  rendererCntrQty,
  rendererBlPolEtd,
  columns1,
  layoutTyp01,
  columns2,
  layoutTyp02,
  columns3,
  layoutTyp03,
  columns4,
  layoutTyp04,
  columns5,
  layoutTyp05,
  fields
}
